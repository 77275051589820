
import { Loader } from '@googlemaps/js-api-loader'
import WorkTimeDropdown from '~/components/version-2023/WorkTimeDropdown'
export default {
  name: 'GoogleMap',
  components: {
    WorkTimeDropdown,
  },
  data() {
    return {
      selectedCity: 4,
      locations: [
        {
          city: 'Praha',
          id: 1,
          markers: [
            {
              title: 'Líbalova 2348/1, 149 00, Praha 11',
              lat: 50.029891708425986,
              lng: 14.508452909286287,
              url: 'https://goo.gl/maps/7Gsg57xbPsBewcEJ9',
              workTime: [
                this.$t('map.close'),
                '09:00–18:00',
                '09:00–18:00',
                '09:00–18:00',
                '09:00–18:00',
                '09:00–18:00',
                '09:00–14:00',
              ],
              phone: '+420608979410',
            },
            {
              title: 'Poděbradská 777, 190 00 Praha 9',
              lat: 50.10268710826411,
              lng: 14.509193533811304,
              url: 'https://goo.gl/maps/HFDTGFjbqzQUCu857',
              workTime: [
                this.$t('map.close'),
                '09:00–19:00',
                '09:00–19:00',
                '09:00–19:00',
                '09:00–19:00',
                '09:00–19:00',
                '09:00–14:00',
              ],
              phone: '+420608540227',
            },
          ],
        },
        {
          city: 'Hradec Králové',
          id: 2,
          markers: [
            {
              title: 'Rovná 181, Březhrad, 503-32 Hradec Králové',
              lat: 50.179596186151684,
              lng: 15.801131286366543,
              url: "https://www.google.com/maps/place/50%C2%B010'46.6%22N+15%C2%B048'04.1%22E/@50.1795011,15.8017632,17z/data=!4m4!3m3!8m2!3d50.1795962!4d15.8011313?entry=ttu",
              workTime: [
                this.$t('map.close'),
                '09:00–18:00',
                '09:00–18:00',
                '09:00–18:00',
                '09:00–18:00',
                '09:00–18:00',
                '09:00–16:00',
              ],
              phone: '+420608809499',
            },
          ],
        },
        {
          city: 'Náchod',
          id: 3,
          markers: [
            {
              title: 'Kladská 207,547 01 Náchod',
              lat: 50.42104200448382,
              lng: 16.182299627125467,
              url: 'https://goo.gl/maps/LRrf1eyuG3FU6zmXA',
              workTime: [
                this.$t('map.close'),
                '07:00–17:00',
                '07:00–17:00',
                '07:00–17:00',
                '07:00–17:00',
                '07:00–17:00',
                '08:00–14:00',
              ],
              phone: '+420739397479',
            },
          ],
        },
        {
          city: 'Brno',
          id: 4,
          markers: [
            {
              title: 'Vídeňská 995/63, 639 00 Brno-střed-Štýřice',
              lat: 49.17962584448812,
              lng: 16.595818386493946,
              url: 'https://goo.gl/maps/goZgQQwKGYnHwgvWA',
              workTime: [
                '09:00–17:00',
                '09:00–19:00',
                '09:00–19:00',
                '09:00–19:00',
                '09:00–19:00',
                '09:00–19:00',
                '09:00–17:00',
              ],
              phone: '+420734550555',
            },
            {
              title: 'Dornych 404/4, 602 00 Brno-střed-Trnitá',
              lat: 49.18952913982714,
              lng: 16.61293900472402,
              url: 'https://goo.gl/maps/PpgUVk7BAFa4Bfuw8',
              workTime: [
                '09:00–17:00',
                '09:00–19:00',
                '09:00–19:00',
                '09:00–19:00',
                '09:00–19:00',
                '09:00–19:00',
                '09:00–17:00',
              ],
              phone: '+420734550505',
            },
          ],
        },
      ],
      loader: null,
      map: null,
      mapOptions: {
        // center: {
        //   lat: 51.113231,
        //   lng: 17.017165,
        // },
        // zoom: 13,
      },
      bounds: null,
      google: null,
    }
  },
  methods: {
    initMap() {
      this.loader
        .load()
        .then((google) => {
          const icon = {
            url: './images/map-pin-dm.svg',
            scaledSize: new google.maps.Size(75, 75),
          }
          this.google = google
          // eslint-disable-next-line no-new
          this.map = new google.maps.Map(
            document.getElementById('map'),
            this.mapOptions
          )
          this.locations.forEach((city) => {
            city.markers.forEach((location) => {
              // eslint-disable-next-line no-new
              const marker = new google.maps.Marker({
                position: { lat: location.lat, lng: location.lng },
                map: this.map,
                title: location.title,
                url: location.url,
                icon,
              })
              this.google.maps.event.addListener(marker, 'click', function () {
                window.open(marker.url, '_blank')
              })
            })
          })
          this.setActualBounds()
        })
        .catch((e) => {
          console.log(e)
        })
    },
    setActualBounds() {
      this.bounds = new this.google.maps.LatLngBounds()
      const city = this.locations.find((item) => item.id === this.selectedCity)
      if (city) {
        city.markers.forEach((location) => {
          this.bounds.extend({ lat: location.lat, lng: location.lng })
        })
        this.map.fitBounds(this.bounds)
        this.map.setOptions({ maxZoom: 17 })
      }
    },
    zoomTo(location) {
      this.map.setZoom(17)
      this.map.panTo({ lat: location.lat, lng: location.lng })
    },
    initLoader() {
      this.loader = new Loader({
        apiKey: this.$config.gmapApiKey,
        language: this.$i18n.locale,
        // version: 'weekly',
        // libraries: ['places'],
      })
      Loader.instance = null
      delete window.google
      this.loader.deleteScript()
    },
  },
  beforeMount() {
    this.initLoader()
  },
  mounted() {
    this.initMap()
  },
}


export default {
  name: 'WorkTimeDropdown',
  data() {
    return {
      isOpen: false,
      day: new Date().getDay(),
    }
  },
  props: ['data'],
  methods: {
    clickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false
      }
    },
  },
  mounted() {
    window.addEventListener('click', this.clickOutside)
  },
  destroyed() {
    window.removeEventListener('click', this.clickOutside)
  },
}
